// app/src/App.js

import './App.css';
import { useEffect, useState } from 'react';
import * as anchor from "@project-serum/anchor";
import { Buffer } from 'buffer';
import idl from './idl.json';
import { Connection, PublicKey, clusterApiUrl, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Program, AnchorProvider, web3, utils } from '@project-serum/anchor';

const { SystemProgram, Keypair } = web3;

window.Buffer = Buffer;
const programID = new PublicKey("EBHimJZSCnM9mQoNSvzx879mgAX4c98UaPmnZxmG6oTR");
const basePK = new PublicKey("F6egapAoZwEir5bqjic6RHVMRzWxCS3YPeydoxohzYD4");
const network = clusterApiUrl("devnet");
const opts = {
  preflightCommitment: "processed",
};

const feedPostApp = Keypair.generate();
const connection = new Connection(network, opts.preflightCommitment);
const provider = new AnchorProvider(connection, window.solana, opts.preflightCommitment);
const program = new Program(idl, programID, provider);

const App = () => {
  const [walletAddress, setWalletAdresss] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [registeredWalletsCount, setRegisteredWalletsCount] = useState(0);

  useEffect(() => {
    const onLoad = () => {
      checkIfWalletConnected();
      getNumberOfRegisteredWallets();
    };
    window.addEventListener("load", onLoad);
    return () => window.removeEventListener("load", onLoad);
  }, []);

  const getProvider = () => {
    const connection = new Connection(network, opts.preflightCommitment);
    const provider = new AnchorProvider(
      connection,
      window.solana,
      opts.preflightCommitment
    );
    return provider;
  };

  const checkIfWalletConnected = async () => {
    const { solana } = window;
    try {
      setLoading(true);
      if (solana && solana.isPhantom) {
        const response = await solana.connect({ onlyIfTrusted: true });
        setWalletAdresss(response.publicKey.toString());
      }
    } catch (error) {
      console.error("Error checking wallet connection:", error);
    } finally {
      setLoading(false);
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    try {
      setLoading(true);
      if (solana) {
        const response = await solana.connect();
        setWalletAdresss(response.publicKey.toString());
      } else {
        alert("Please Install Solana's Phantom Wallet");
      }
    } catch (error) {
      console.error("Wallet connect error:", error);
    } finally {
      setLoading(false);
    }
  };

  const registerWallet = async () => {
    if (!window.solana || !window.solana.isConnected) {
      console.error("Wallet not connected");
      return;
    }
  
    try {
      setLoading(true);
      const provider = getProvider(); // Ensures the provider is set up correctly
      const program = new anchor.Program(idl, programID, provider);
  
      const tx = await program.methods.register()
        .accounts({
          baseAccount: basePK,
          user: window.solana.publicKey, // Use the public key from the wallet
        })
        .rpc();
      console.log("Successfully registered wallet. Transaction ID:", tx);
      
      setRegistered(true);
      
      getNumberOfRegisteredWallets();
      
    } catch (error) {
      console.error("Failed to send transaction:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const getNumberOfRegisteredWallets = async () => {
    try {
      setLoading(true);
      const account = await program.account.baseAccount.fetch(basePK);
      setRegisteredWalletsCount(account.totalUsers.toNumber());
      console.log("Total registered wallets:", account.totalUsers.toNumber());
    } catch (error) {
      console.error("Failed to fetch the number of registered wallets:", error);
      setRegisteredWalletsCount(0);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header">Vold</p>
          <p className="sub-text">Join Vold Waitlist</p>
          <p>
            {!loading ? (
              <button
                className="cta-button connect-wallet-button"
                onClick={!walletAddress ? connectWallet : undefined}
              >
                {!walletAddress ? "Connect Wallet" : `Connected: ${walletAddress}`}
              </button>
            ) : "Loading..."}
          </p>
          {walletAddress && (
            <button className="cta-button send-transaction-button" onClick={registerWallet}>
              Register My Wallet
            </button>            
          )}
          <button
          className="cta-button"
          onClick={getNumberOfRegisteredWallets}
        >
          Update Number of Registered Users
        </button>
        {registered ? (
         <p> Successfully Registered!
         </p>
        ) : "" }
          <p className="sub-text">
          Number of Registered Wallets: {registeredWalletsCount}          
        </p>
        </div>
      </div>
    </div>
  );
};

export default App;
